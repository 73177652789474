import {TUser} from 'shared/server';

import {OTEL_WEB_ENABLED} from '../platform/config';
import {initDataDog, setDataDogUser} from './datadog';
import {initOtel} from './otel';

export const initTelemetry = () => {
  initDataDog();
  if (OTEL_WEB_ENABLED) initOtel();
};

export const setTelemetryUser = (user: TUser) => {
  setDataDogUser(user);
};
