/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/*
This file is generated by ./generate_bam_permissions.py: do not hand-edit

Generated at: 2024-10-15 20:03:15.249238
*/

export const ALL_PARTNERS: string = 'all_partners';

export const AUDIT_LOG: string = 'audit_log';

export const BASE_UI: string = 'base_ui';

export const CRYSTAL_WINDOW: string = 'crystal_window';

export const ENGINEERING_TOOLS: string = 'engineering_tools';

export const FEATURE_GATING_RO: string = 'feature_gating_ro';

export const PARTNER_FACING_TOOLS: string = 'partner_facing_tools';

export const PARTNER_FACING_TOOLS_RESTRICTED: string = 'partner_facing_tools_restricted';

export const PARTNER_INTERNAL_TOOLS: string = 'partner_internal_tools';

export const PARTNER_TIER_GATE_OVERRIDE: string = 'partner_tier_gate_override';

export const PRIVATE_PROOFS: string = 'private_proofs';

export const SENT_EMAILS: string = 'sent_emails';

export const VTE: string = 'vte';

export const IP_MANAGEMENT: string = 'ip_management';

export const ADVERTISE_CAMPAIGNS_CREATE: string = 'advertise.campaigns.create';
export const ADVERTISE_CAMPAIGNS_DELETE: string = 'advertise.campaigns.delete';
export const ADVERTISE_CAMPAIGNS_GET: string = 'advertise.campaigns.get';
export const ADVERTISE_CAMPAIGNS_PUBLISH: string = 'advertise.campaigns.publish';
export const ADVERTISE_CAMPAIGNS_UPDATE: string = 'advertise.campaigns.update';

export const BAM_API_CREATE: string = 'bam.api.create';
export const BAM_NAMESPACE_GET_POLICY: string = 'bam.namespace.get_policy';
export const BAM_NAMESPACE_SET_POLICY: string = 'bam.namespace.set_policy';
export const BAM_PERMISSIONS_GET: string = 'bam.permissions.get';
export const BAM_USERS_RESET_PASSWORD: string = 'bam.users.reset_password';
export const BAM_USERS_SEARCH: string = 'bam.users.search';

export const CHRONOMETER_JOB_GET: string = 'chronometer.job.get';

export const DATA_EVENTS_CREATE: string = 'data.events.create';
export const DATA_EVENTS_DELETE: string = 'data.events.delete';
export const DATA_EVENTS_GET: string = 'data.events.get';
export const DATA_EVENTS_UPDATE: string = 'data.events.update';
export const DATA_IMPORTS_CREATE: string = 'data.imports.create';
export const DATA_IMPORTS_GET: string = 'data.imports.get';
export const DATA_IMPORTS_UPDATE: string = 'data.imports.update';
export const DATA_INTEGRATIONS_CREATE: string = 'data.integrations.create';
export const DATA_INTEGRATIONS_DELETE: string = 'data.integrations.delete';
export const DATA_INTEGRATIONS_GET: string = 'data.integrations.get';
export const DATA_INTEGRATIONS_UPDATE: string = 'data.integrations.update';
export const DATA_UNSUBSCRIBES_CREATE: string = 'data.unsubscribes.create';
export const DATA_UNSUBSCRIBES_DELETE: string = 'data.unsubscribes.delete';
export const DATA_UNSUBSCRIBES_GET: string = 'data.unsubscribes.get';
export const DATA_UNSUBSCRIBES_UPDATE: string = 'data.unsubscribes.update';

export const EMAIL_CHANNELS_SEND_EMAIL: string = 'email.channels.send_email';
export const EMAIL_CHANNELS_SYNC_TO_AUDIENCE: string = 'email.channels.sync_to_audience';

export const EXTENSIBILITY_EXPORTS_CREATE: string = 'extensibility.exports.create';
export const EXTENSIBILITY_EXPORTS_GET: string = 'extensibility.exports.get';

export const MARKETING_ANALYTICS_GET: string = 'marketing.analytics.get';
export const MARKETING_AUDIENCEINSIGHTS_GET: string = 'marketing.audienceInsights.get';
export const MARKETING_AUDIENCES_CREATE: string = 'marketing.audiences.create';
export const MARKETING_AUDIENCES_DELETE: string = 'marketing.audiences.delete';
export const MARKETING_AUDIENCES_GET: string = 'marketing.audiences.get';
export const MARKETING_AUDIENCES_UPDATE: string = 'marketing.audiences.update';
export const MARKETING_CAMPAIGNS_CREATE: string = 'marketing.campaigns.create';
export const MARKETING_CAMPAIGNS_DELETE: string = 'marketing.campaigns.delete';
export const MARKETING_CAMPAIGNS_GET: string = 'marketing.campaigns.get';
export const MARKETING_CAMPAIGNS_PUBLISH: string = 'marketing.campaigns.publish';
export const MARKETING_CAMPAIGNS_UPDATE: string = 'marketing.campaigns.update';
export const MARKETING_CATALOG_DELETE: string = 'marketing.catalog.delete';
export const MARKETING_CATALOG_SEARCH: string = 'marketing.catalog.search';
export const MARKETING_CATALOG_SEARCH_UI: string = 'marketing.catalog.search_ui';
export const MARKETING_COUPONS_GET: string = 'marketing.coupons.get';
export const MARKETING_CUSTOMER360_GET: string = 'marketing.customer360.get';
export const MARKETING_GLOBAL_WIDGETS_CREATE: string = 'marketing.global_widgets.create';
export const MARKETING_GLOBAL_WIDGETS_DELETE: string = 'marketing.global_widgets.delete';
export const MARKETING_GLOBAL_WIDGETS_GET: string = 'marketing.global_widgets.get';
export const MARKETING_GLOBAL_WIDGETS_UPDATE: string = 'marketing.global_widgets.update';
export const MARKETING_IMAGES_CREATE: string = 'marketing.images.create';
export const MARKETING_IMAGES_DELETE: string = 'marketing.images.delete';
export const MARKETING_IMAGES_GET: string = 'marketing.images.get';
export const MARKETING_PROOF_METADATA_GET: string = 'marketing.proof_metadata.get';
export const MARKETING_REPORTS_CREATE: string = 'marketing.reports.create';
export const MARKETING_REPORTS_DELETE: string = 'marketing.reports.delete';
export const MARKETING_REPORTS_GET: string = 'marketing.reports.get';
export const MARKETING_REPORTS_UPDATE: string = 'marketing.reports.update';
export const MARKETING_SENT_MESSAGES_GET: string = 'marketing.sent_messages.get';
export const MARKETING_TEMPLATE_PARTIALS_GET: string = 'marketing.template_partials.get';
export const MARKETING_TEMPLATES_CREATE: string = 'marketing.templates.create';
export const MARKETING_TEMPLATES_DELETE: string = 'marketing.templates.delete';
export const MARKETING_TEMPLATES_GET: string = 'marketing.templates.get';
export const MARKETING_TEMPLATES_UPDATE: string = 'marketing.templates.update';

export const ONSITE_CHANNEL: string = 'onsite.channel';

export const PARTNER_ADMIN_ACCOUNT_SETTINGS_GET: string = 'partner_admin.account_settings.get';
export const PARTNER_ADMIN_ACCOUNT_SETTINGS_UPDATE: string = 'partner_admin.account_settings.update';
export const PARTNER_ADMIN_BILLING_GET: string = 'partner_admin.billing.get';
export const PARTNER_ADMIN_CAMPAIGN_NOTIFICATIONS_GET: string = 'partner_admin.campaign_notifications.get';
export const PARTNER_ADMIN_CAMPAIGN_NOTIFICATIONS_UPDATE: string = 'partner_admin.campaign_notifications.update';
export const PARTNER_ADMIN_CLICK_DASHBOARD_GET: string = 'partner_admin.click_dashboard.get';
export const PARTNER_ADMIN_CRONS_GET: string = 'partner_admin.crons.get';
export const PARTNER_ADMIN_CRONS_UI: string = 'partner_admin.crons.ui';
export const PARTNER_ADMIN_CRONS_UPDATE: string = 'partner_admin.crons.update';
export const PARTNER_ADMIN_DOCUMENTATION_GET: string = 'partner_admin.documentation.get';
export const PARTNER_ADMIN_FEATURE_UPDATE: string = 'partner_admin.feature.update';
export const PARTNER_ADMIN_INTEGRATION_GUIDE_GET: string = 'partner_admin.integration_guide.get';
export const PARTNER_ADMIN_PLAN_GET: string = 'partner_admin.plan.get';
export const PARTNER_ADMIN_PRODUCT_EXCLUSIONS_CREATE: string = 'partner_admin.product_exclusions.create';
export const PARTNER_ADMIN_PRODUCT_EXCLUSIONS_GET: string = 'partner_admin.product_exclusions.get';
export const PARTNER_ADMIN_SEND_TIME_OPTIMIZATION_GET: string = 'partner_admin.send_time_optimization.get';
export const PARTNER_ADMIN_SEND_TIME_OPTIMIZATION_SET: string = 'partner_admin.send_time_optimization.set';
export const PARTNER_ADMIN_SFTP_CREATE: string = 'partner_admin.sftp.create';
export const PARTNER_ADMIN_SFTP_GET: string = 'partner_admin.sftp.get';
export const PARTNER_ADMIN_SFTP_RESET_PASSWORD: string = 'partner_admin.sftp.reset_password';
export const PARTNER_ADMIN_SFTP_UPDATE: string = 'partner_admin.sftp.update';

export const PRIVACY_AUDIT_GET: string = 'privacy.audit.get';
export const PRIVACY_RTBF_CREATE: string = 'privacy.rtbf.create';

export const SERAPIAN_COUNTERS_FLUSH_POST: string = 'serapian_counters.flush.post';

export const SITE_CAMPAIGNS_CREATE: string = 'site.campaigns.create';
export const SITE_CAMPAIGNS_DELETE: string = 'site.campaigns.delete';
export const SITE_CAMPAIGNS_GET: string = 'site.campaigns.get';
export const SITE_CAMPAIGNS_PUBLISH: string = 'site.campaigns.publish';
export const SITE_CAMPAIGNS_UPDATE: string = 'site.campaigns.update';

export const TRANSACTIONAL_API: string = 'transactional.api';

export const JSAUTH_API: string = 'jsauth.api';
export const ALL_PERMISSIONS = [
  ALL_PARTNERS,
  AUDIT_LOG,
  BASE_UI,
  CRYSTAL_WINDOW,
  ENGINEERING_TOOLS,
  FEATURE_GATING_RO,
  PARTNER_FACING_TOOLS,
  PARTNER_FACING_TOOLS_RESTRICTED,
  PARTNER_INTERNAL_TOOLS,
  PARTNER_TIER_GATE_OVERRIDE,
  PRIVATE_PROOFS,
  SENT_EMAILS,
  VTE,
  IP_MANAGEMENT,
  ADVERTISE_CAMPAIGNS_CREATE,
  ADVERTISE_CAMPAIGNS_DELETE,
  ADVERTISE_CAMPAIGNS_GET,
  ADVERTISE_CAMPAIGNS_PUBLISH,
  ADVERTISE_CAMPAIGNS_UPDATE,
  BAM_API_CREATE,
  BAM_NAMESPACE_GET_POLICY,
  BAM_NAMESPACE_SET_POLICY,
  BAM_PERMISSIONS_GET,
  BAM_USERS_RESET_PASSWORD,
  BAM_USERS_SEARCH,
  CHRONOMETER_JOB_GET,
  DATA_EVENTS_CREATE,
  DATA_EVENTS_DELETE,
  DATA_EVENTS_GET,
  DATA_EVENTS_UPDATE,
  DATA_IMPORTS_CREATE,
  DATA_IMPORTS_GET,
  DATA_IMPORTS_UPDATE,
  DATA_INTEGRATIONS_CREATE,
  DATA_INTEGRATIONS_DELETE,
  DATA_INTEGRATIONS_GET,
  DATA_INTEGRATIONS_UPDATE,
  DATA_UNSUBSCRIBES_CREATE,
  DATA_UNSUBSCRIBES_DELETE,
  DATA_UNSUBSCRIBES_GET,
  DATA_UNSUBSCRIBES_UPDATE,
  EMAIL_CHANNELS_SEND_EMAIL,
  EMAIL_CHANNELS_SYNC_TO_AUDIENCE,
  EXTENSIBILITY_EXPORTS_CREATE,
  EXTENSIBILITY_EXPORTS_GET,
  MARKETING_ANALYTICS_GET,
  MARKETING_AUDIENCEINSIGHTS_GET,
  MARKETING_AUDIENCES_CREATE,
  MARKETING_AUDIENCES_DELETE,
  MARKETING_AUDIENCES_GET,
  MARKETING_AUDIENCES_UPDATE,
  MARKETING_CAMPAIGNS_CREATE,
  MARKETING_CAMPAIGNS_DELETE,
  MARKETING_CAMPAIGNS_GET,
  MARKETING_CAMPAIGNS_PUBLISH,
  MARKETING_CAMPAIGNS_UPDATE,
  MARKETING_CATALOG_DELETE,
  MARKETING_CATALOG_SEARCH,
  MARKETING_CATALOG_SEARCH_UI,
  MARKETING_COUPONS_GET,
  MARKETING_CUSTOMER360_GET,
  MARKETING_GLOBAL_WIDGETS_CREATE,
  MARKETING_GLOBAL_WIDGETS_DELETE,
  MARKETING_GLOBAL_WIDGETS_GET,
  MARKETING_GLOBAL_WIDGETS_UPDATE,
  MARKETING_IMAGES_CREATE,
  MARKETING_IMAGES_DELETE,
  MARKETING_IMAGES_GET,
  MARKETING_PROOF_METADATA_GET,
  MARKETING_REPORTS_CREATE,
  MARKETING_REPORTS_DELETE,
  MARKETING_REPORTS_GET,
  MARKETING_REPORTS_UPDATE,
  MARKETING_SENT_MESSAGES_GET,
  MARKETING_TEMPLATE_PARTIALS_GET,
  MARKETING_TEMPLATES_CREATE,
  MARKETING_TEMPLATES_DELETE,
  MARKETING_TEMPLATES_GET,
  MARKETING_TEMPLATES_UPDATE,
  ONSITE_CHANNEL,
  PARTNER_ADMIN_ACCOUNT_SETTINGS_GET,
  PARTNER_ADMIN_ACCOUNT_SETTINGS_UPDATE,
  PARTNER_ADMIN_BILLING_GET,
  PARTNER_ADMIN_CAMPAIGN_NOTIFICATIONS_GET,
  PARTNER_ADMIN_CAMPAIGN_NOTIFICATIONS_UPDATE,
  PARTNER_ADMIN_CLICK_DASHBOARD_GET,
  PARTNER_ADMIN_CRONS_GET,
  PARTNER_ADMIN_CRONS_UI,
  PARTNER_ADMIN_CRONS_UPDATE,
  PARTNER_ADMIN_DOCUMENTATION_GET,
  PARTNER_ADMIN_FEATURE_UPDATE,
  PARTNER_ADMIN_INTEGRATION_GUIDE_GET,
  PARTNER_ADMIN_PLAN_GET,
  PARTNER_ADMIN_PRODUCT_EXCLUSIONS_CREATE,
  PARTNER_ADMIN_PRODUCT_EXCLUSIONS_GET,
  PARTNER_ADMIN_SEND_TIME_OPTIMIZATION_GET,
  PARTNER_ADMIN_SEND_TIME_OPTIMIZATION_SET,
  PARTNER_ADMIN_SFTP_CREATE,
  PARTNER_ADMIN_SFTP_GET,
  PARTNER_ADMIN_SFTP_RESET_PASSWORD,
  PARTNER_ADMIN_SFTP_UPDATE,
  PRIVACY_AUDIT_GET,
  PRIVACY_RTBF_CREATE,
  SERAPIAN_COUNTERS_FLUSH_POST,
  SITE_CAMPAIGNS_CREATE,
  SITE_CAMPAIGNS_DELETE,
  SITE_CAMPAIGNS_GET,
  SITE_CAMPAIGNS_PUBLISH,
  SITE_CAMPAIGNS_UPDATE,
  TRANSACTIONAL_API,
  JSAUTH_API,
];
