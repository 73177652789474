import {isServer} from 'shared/server/is_client';

declare global {
  interface Window {
    nextserver_env: TEnv;
    nextserver_version: string;
    otel_web_enabled: boolean;
  }
}

export const ENV = isServer() ? (process.env.ENV as TEnv) || 'qa' : window.nextserver_env;

export const VERSION = isServer() ? process.env.DD_VERSION || 'dev' : window.nextserver_version;

export const OTEL_WEB_ENABLED = isServer()
  ? process.env.OTEL_ENABLED === 'true' && process.env.OTEL_COLLECTOR_URL_HTTP != null
  : window.otel_web_enabled;

export const ORIGIN = isServer() ? (process.env.NEXTAUTH_URL as string) : window.location.origin;

export const IS_NEXTAUTH_SECURE = ORIGIN.startsWith('https:');

export const NEXTAUTH_COOKIE_PREFIX = IS_NEXTAUTH_SECURE ? '__Secure-' : '';

// These are public. Do not include secrets here.
const DEFAULT_CONFIG = {
  TINYMCE_SCRIPT_URL: 'https://cdnjs.cloudflare.com/ajax/libs/tinymce/4.9.11/tinymce.min.js',
  JWT_REFRESH_BEFORE_EXPIRY_MS: 5 * 60 * 1000,
  OAUTH_STATE_MAX_AGE_SECONDS: 15 * 60,
  DD_APPLICATION_ID: '7af73436-a87e-4634-8c3b-4034f8d2c63b',
  DD_CLIENT_TOKEN: 'pub9573d1fafd21a8f2f89b8b3ac71699ca',
  DD_SERVICE_NAME: 'nextserver',
  DD_ERROR_LOG_BYPASS: 'logging: bypass',
};

const CONFIG_PER_ENV = {
  qa: {
    bluecore_api_url: 'https://api.qa.bluecore.app',
    env: 'qa',
    monolith_url: 'https://qa.bluecore.com',
    py3_monolith_url: 'https://qa3.bluecore.com',
    nextserver_url: 'https://app.qa.bluecore.app',
    okta_clientid: 'GDdCxsmCEBUfLHJCD1sQ',
    okta_issuer: 'https://bluecore.oktapreview.com',
  },
  prod_shadow: {
    bluecore_api_url: 'https://api.bluecore.app',
    env: 'prod',
    monolith_url: 'https://prod-shadow.bluecore.com',
    py3_monolith_url: 'https://prod-shadow-dot-client-site-py3-dot-triggeredmail.appspot.com',
    nextserver_url: 'https://shadow-app.bluecore.com',
    okta_clientid: 'OX9PfjGce13DkS4FB7Tq',
    okta_issuer: 'https://bluecore.okta.com',
  },
  prod: {
    bluecore_api_url: 'https://api.bluecore.app',
    env: 'prod',
    monolith_url: 'https://bluecore.com',
    py3_monolith_url: 'https://ae-ingress.bluecore.com',
    nextserver_url: 'https://app.bluecore.com',
    okta_clientid: 'OX9PfjGce13DkS4FB7Tq',
    okta_issuer: 'https://bluecore.okta.com',
  },
};

export type TEnv = keyof typeof CONFIG_PER_ENV;

export type TConfig = typeof CONFIG;

export const CONFIG = {...DEFAULT_CONFIG, ...(CONFIG_PER_ENV[ENV as TEnv] || CONFIG_PER_ENV.qa)};
