/**
 * This file is the entrypoint for functions that are called from the server.
 * Every exported function should have a client-side counterpart, even if it's a no-op.
 */
import {trace} from '@opentelemetry/api';
import axios, {AxiosError, HttpStatusCode} from 'axios';
import {CONFIG} from 'shared/platform/config';

import {isClient} from './is_client';

const DEFAULT_TIMEOUT_MS = 30000;
const RETRY_WAIT_MS = 1000;

const axiosClient = axios.create({
  baseURL: CONFIG.monolith_url,
  timeout: DEFAULT_TIMEOUT_MS,
});

/**
 * The monolith can be quite slow to respond, so we hammer it by sending a new request every RETRY_WAIT_MS.
 * The first request to either succeed or fail (ie from a timeout or other error) gets returned.
 */
const axiosGetWithRetries = <T>(url: string, jwt: string): Promise<T> => {
  const abortControllers: AbortController[] = [];
  return recursiveRetries<T>(url, jwt, abortControllers, 1).finally(() => {
    // Abort all pending requests now that we have a result
    abortControllers.forEach((controller) => controller.abort());
  });
};

const recursiveRetries = <T>(
  url: string,
  jwt: string,
  abortControllers: AbortController[],
  counter: number,
): Promise<T> => {
  const abortController = new AbortController();
  abortControllers.push(abortController);

  const promise1 = axiosGet<T>(url, jwt, abortController);
  const promise2 = new Promise((resolve) => setTimeout(resolve, RETRY_WAIT_MS)).then(() => {
    if (abortController.signal.aborted) {
      throw new Error('Aborted promise');
    }
    if (counter * RETRY_WAIT_MS >= DEFAULT_TIMEOUT_MS) {
      throw new Error('Too many retries');
    }
    return recursiveRetries<T>(url, jwt, abortControllers, counter + 1);
  });

  return Promise.race([promise1, promise2]);
};

const axiosGet = <T>(url: string, jwt: string, abortController: AbortController): Promise<T> => {
  return axiosClient
    .get<T>(url, {
      headers: {Authorization: `Bearer ${jwt}`},
      signal: abortController.signal,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      if (error.code === 'ERR_NETWORK') {
        // We can ignore network errors with a no-op promise
        return new Promise(() => null);
      }
      throw error;
    });
};

/**
 * This pulls the full list of permissions from the monolith.
 * @param partner
 * @returns
 */
export const getBamPermissions = async (partner = 'bluecore', jwt: string) => {
  if (isClient()) return Array.from(window.permissions).flat();

  try {
    return await axiosGetWithRetries<string[]>(`/api/rest/bam/permissions/${partner}`, jwt);
  } catch (error) {
    // ignore 401 errors, in these cases the user doesn't have access to this partner and we show the 404 page
    if ((error as AxiosError).response?.status === HttpStatusCode.Unauthorized) return [];
    throw error;
  }
};

/**
 * This pulls the full list of enabled feature gates from the monolith.
 * @param partner
 * @returns
 */
export const getFeatureFlags = async (partner = 'bluecore', jwt: string): Promise<string[]> => {
  if (isClient()) return Array.from(window.enabled_features);

  try {
    return await axiosGetWithRetries<string[]>(`/api/rest/feature_gating/active_features/${partner}`, jwt);
  } catch (error) {
    // ignore 401 errors, in these cases the user doesn't have access to this partner and we show the 404 page
    if ((error as AxiosError).response?.status === HttpStatusCode.Unauthorized) return [];
    throw error;
  }
};

export type TUser = {
  created: string;
  email: string;
  email_aliases: string[];
  first_name: string;
  google_user_id: string;
  groups: string[];
  id: number;
  internal: boolean;
  last_login_time: string;
  last_name: string;
  managed_by_external_idp: boolean;
  no_dot_email: string;
  origanization: string | null;
  policy_key_names: string[];
  raw_email: string;
  suspended: boolean;
  updated: string;
  okta_user_ids: string;
};

export const getUser = async (jwt: string) =>
  isClient() ? window.user : await axiosGetWithRetries<TUser>('/api/rest/bam/user', jwt);

export const getUserByEmail = async (jwt: string) =>
  await axiosClient
    .get<TUser>('/api/rest/bam/user_by_email', {headers: {Authorization: `Bearer ${jwt}`}})
    .then((response) => response.data);

export type TPartner = {
  parent_namespace: string;
  status: string;
  display_name: string;
  name: string;
  created: string;
};

export const getPartner = async (partner: string, jwt: string) => {
  if (isClient()) return window.partnerInfo;

  try {
    return await axiosGetWithRetries<TPartner>(`/api/rest/partner/status/${partner}`, jwt);
  } catch (error) {
    // ignore 400 errors, in these cases there's an invalid partner in the url and we show the 404 page
    if ((error as AxiosError).response?.status === HttpStatusCode.BadRequest) return undefined;
    // ignore 401 errors, in these cases the user doesn't have access to this partner and we show the 404 page
    if ((error as AxiosError).response?.status === HttpStatusCode.Unauthorized) return undefined;
    throw error;
  }
};

export const getRoles = async (partner: string, jwt: string) => {
  if (isClient()) return window.roles;

  try {
    return await axiosGetWithRetries<string[]>(`/api/rest/bam/roles/${partner}`, jwt);
  } catch (error) {
    // ignore 401 errors, in these cases the user doesn't have access to this partner and we show the 404 page
    if ((error as AxiosError).response?.status === HttpStatusCode.Unauthorized) return [];
    throw error;
  }
};

export const getAvailablePartners = async (jwt: string) => {
  return await axiosGetWithRetries<string[]>('/api/rest/available_namespaces/', jwt);
};

type TIntegrationSettings = {
  signed_terms_of_service: boolean;
};

export const isTermsOfServiceSigned = async (partner: string, jwt: string) => {
  if (isClient()) return window.isTosSigned;

  try {
    return (await axiosGetWithRetries<TIntegrationSettings>(`/api/rest/integration_settings/${partner}`, jwt))
      ?.signed_terms_of_service;
  } catch (error) {
    if ((error as AxiosError).response?.status === HttpStatusCode.Unauthorized) return false;
    // ignore 400 errors, this usually means that the partner is not found and we show the 404 page
    if ((error as AxiosError).response?.status === HttpStatusCode.BadRequest) return false;
    throw error;
  }
};

export const updateLastLoginTime = (jwt: string) =>
  axiosClient
    .post<TUser>('/api/rest/bam/last_login_time', {}, {headers: {Authorization: `Bearer ${jwt}`}})
    .then((response) => response.data);

/**
 * Returns the value that should be used in the `traceparent` meta tag.
 * It contains information about the server's request trace, which will be used as the parent trace for the client-side spans.
 */
export const getTraceParent = () => {
  if (isClient()) return document.querySelector('meta[name="traceparent"]')?.getAttribute('content') || '';

  const context = trace.getActiveSpan()?.spanContext();

  if (!context) return '';

  return `00-${context.traceId}-${context.spanId}-0${context.traceFlags}`;
};
