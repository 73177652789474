import React, {ReactNode, useEffect} from 'react';
import cookie from 'cookie';
import {IncomingMessage, ServerResponse} from 'http';
import moment from 'moment-timezone';
import {Session} from 'next-auth';
import {decode, encode, JWT} from 'next-auth/jwt';
import {getSession as getSessionFromApi, SessionProvider as NextAuthSessionProvider} from 'next-auth/react';
import {deduplicateAsync} from 'shared/deduplicate_async';
import {IS_NEXTAUTH_SECURE, NEXTAUTH_COOKIE_PREFIX} from 'shared/platform/config';
import {isClient} from 'shared/server/is_client';

import {refreshAccessToken} from './refresh_access_token';

const getSessionFromJwt = (jwt: JWT): Session => ({
  user: {email: jwt.email},
  expires: new Date(jwt.accessTokenExpires as number).toISOString(),
  tokenExpires: jwt.accessTokenExpires as number,
  rawJWT: jwt.rawJWT as string,
  oktaSub: jwt.oktaSub as string,
  error: jwt.error as string,
});

const getJWTFromCookie = async (cookie: string) => {
  try {
    const jwt = await decode({
      token: cookie,
      secret: process.env.NEXTAUTH_SECRET || '',
    });
    return jwt;
  } catch (error) {
    return null;
  }
};

let clientSession: Session | null = null;

/***
 * A session provider that saves the session into a variable client-side to avoid fetching it every time from the server.
 */
export const SessionProvider: React.FC<{session: Session | null; children?: ReactNode}> = ({session, children}) => {
  useEffect(() => {
    clientSession = session;
  }, [session]);

  return (
    <NextAuthSessionProvider session={session} refetchOnWindowFocus={false}>
      {children}
    </NextAuthSessionProvider>
  );
};

const updateSessionOnClient = deduplicateAsync(async () => {
  const session = await getSessionFromApi();
  clientSession = session;
  return session;
});

/***
 * Returns the session client-side.
 * Saves the session into a variable to avoid fetching it every time from the server.
 * It is fetched only when the short-lived Okta JWT is about to expire.
 */
export const getClientSession = async () => {
  if (clientSession && clientSession.tokenExpires > moment().add(1, 'minute').unix() * 1000) return clientSession;
  return await updateSessionOnClient();
};

export const SESSION_COOKIE_KEY = `${NEXTAUTH_COOKIE_PREFIX}next-auth.session-token`;

/**
 * Clears the session cookie on a server response.
 */
export const clearSession = (res: ServerResponse) => {
  res.setHeader(
    'Set-Cookie',
    cookie.serialize(SESSION_COOKIE_KEY, '', {
      path: '/',
      maxAge: 0,
      httpOnly: true,
      secure: IS_NEXTAUTH_SECURE,
      sameSite: 'lax',
    }),
  );
};

/**
 * Sets the session cookie on a server response.
 */
export const setSession = async (res: ServerResponse, session: JWT) => {
  const encryptedJwt = await encode({token: session, secret: process.env.NEXTAUTH_SECRET || ''});

  res.setHeader(
    'Set-Cookie',
    cookie.serialize(SESSION_COOKIE_KEY, encryptedJwt, {
      path: '/',
      maxAge: 30 * 24 * 60 * 60, // 30 days,
      httpOnly: true,
      secure: IS_NEXTAUTH_SECURE,
      sameSite: 'lax',
    }),
  );
};

/**
 * Gets the next-auth session.
 * Ideally we'd use getServerSession from next-auth on server side, but that requires upgrading to Next 13 and React 18,
 * so this is a workaround to avoid sending the extra request by calling getSession on server-side.
 * TODO: get rid of this and use getServerSession once we upgrade to Next 13.
 */
export const getSession = async (ctx: {req?: IncomingMessage; res?: ServerResponse}) => {
  if (isClient()) return getClientSession();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cookies = (ctx.req as any)?.cookies;

  if (!cookies) return null;

  const jwt = await getJWTFromCookie(cookies[SESSION_COOKIE_KEY]);

  if (!jwt) return null;

  const session = getSessionFromJwt(jwt);
  const isTokenExpired = session.tokenExpires ? session.tokenExpires < Date.now() : true;

  if (!isTokenExpired) return session;

  const refreshedJwt = await refreshAccessToken(jwt);

  if (ctx.res) await setSession(ctx.res, refreshedJwt);

  return getSessionFromJwt(refreshedJwt);
};
