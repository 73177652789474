import 'shared/devtools/api-worker';

import React, {ReactNode, useEffect} from 'react';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import axios, {InternalAxiosRequestConfig, isAxiosError} from 'axios';
import dynamic from 'next/dynamic';
import {useSession} from 'next-auth/react';
import {client as auxiliaryClient} from 'shared/frontend-utils/api_utils';
import {captureError} from 'shared/frontend-utils/error_logger';
import {
  ExtendedAxiosError,
  isRequestErrorReportable,
  RequestError,
  requestErrorBuilder,
} from 'shared/frontend-utils/request_error';
import {CONFIG} from 'shared/platform/config';
import {getClientSession} from 'shared/platform/session';

const ReactQueryDevtools = dynamic(
  () => import('@tanstack/react-query-devtools').then((mod) => mod.ReactQueryDevtools),
  {ssr: false},
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {refetchOnWindowFocus: false, staleTime: Infinity, retry: false},
  },
});

export function setupAxios(jwt: string) {
  axios.defaults.headers['Authorization'] = `Bearer ${jwt}`;
  axios.defaults.baseURL = CONFIG.bluecore_api_url;
}

const handleRequest = async (config: InternalAxiosRequestConfig) => {
  const session = await getClientSession();
  config.headers['Authorization'] = `Bearer ${session?.rawJWT ?? ''}`;
  return config;
};

const handleResponseWithError = (err: unknown) => {
  const error = requestErrorBuilder(err);

  if (!isAxiosError(err)) {
    captureError(error.message); // send the error to DataDog
    return Promise.reject(err);
  }

  const {method, url} = err.config as InternalAxiosRequestConfig;
  const requestErrorInstance = new RequestError(`${method?.toUpperCase()}: ${url}`, error);
  if (isRequestErrorReportable(error)) {
    captureError(requestErrorInstance);
  }

  const extendedAxiosError = new ExtendedAxiosError(err, error, true);
  return Promise.reject(extendedAxiosError);
};

const clearInterceptors = (): void => {
  axios.interceptors.request.clear();
  auxiliaryClient.interceptors.request.clear();
  axios.interceptors.response.clear();
  auxiliaryClient.interceptors.response.clear();
};

const useSetupAxios = () => {
  const {data: session} = useSession();
  const jwt = session?.rawJWT;

  useEffect(() => {
    setupAxios(jwt ?? '');
  }, [jwt]);

  useEffect(() => {
    // request interceptors
    axios.interceptors.request.use(handleRequest);
    auxiliaryClient.interceptors.request.use(handleRequest);

    // response interceptors
    axios.interceptors.response.use(
      (response) => response, // Any status code that lies within the range of 2xx causes this function to trigger
      (error) => handleResponseWithError(error), // Any status codes that fall outside the range of 2xx cause this function to trigger
    );
    auxiliaryClient.interceptors.response.use(
      (response) => response,
      (err) => handleResponseWithError(err),
    );

    return () => clearInterceptors();
  }, []);
};

export const ReactQueryProvider: React.FC<{children?: ReactNode}> = ({children}) => {
  useSetupAxios();

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools buttonPosition="bottom-right" />
    </QueryClientProvider>
  );
};
