/* eslint-disable complexity */
import React, {useEffect, useMemo} from 'react';
import {AxiosError, HttpStatusCode} from 'axios';
import {AppContext, AppInitialProps, AppProps} from 'next/app';
import Error from 'next/error';
import {Session} from 'next-auth';
import {appWithTranslation} from 'next-i18next';
import {ENGINEERING_TOOLS} from 'shared/authorization/permissions';
import {Unauthorized} from 'shared/components/unauthorized';
import {CustomStatusCodes} from 'shared/constants/custom_status_codes';
import {hasAllPermissions, redirectToLogin, redirectToRoot, redirectToTermsOfService} from 'shared/platform/auth';
import {stringifyError} from 'shared/platform/error';
import {PageHead} from 'shared/platform/head';
import {fetchInitialData, TNextServerPage} from 'shared/platform/page';
import {ReactQueryProvider, setupAxios} from 'shared/platform/react_query';
import {clearSession, getSession, SessionProvider} from 'shared/platform/session';
import {getGlobals, setWindowVariables, TGlobalVariables} from 'shared/platform/window';
import {TPartner, TUser} from 'shared/server';
import {isClient} from 'shared/server/is_client';
import {initTelemetry, setTelemetryUser} from 'shared/telemetry';

import {ThemeProvider} from '@triggermail/bluecore-ui-components/mui';

import nextI18nConfig from '../../next-i18next.config';
import NotFoundPage from './404';

import 'shared/sass/admin/admin.scss';
import '@triggermail/bluecore-ui-components/styles.css';
import 'shared/styles/globals.css';

if (isClient()) initTelemetry();

type TAppContext = AppContext & {
  Component: TNextServerPage;
};

type TAppInitialProps = AppInitialProps & {
  bamPermissions: string[];
  featureGates: string[];
  user?: TUser;
  partner?: string;
  partnerInfo?: TPartner;
  roles: string[];
  session: Session | null;
  error?: unknown;
  isTosSigned: boolean;
  isAuthorized: boolean;
  traceparent: string;
};

export type TAppProps = AppProps &
  TAppInitialProps & {
    Component: TNextServerPage;
  };

type TAppShell = React.FC<TAppProps> & {
  getInitialProps: (appContext: TAppContext) => Promise<TAppInitialProps | Record<string, never>>;
};

const AppContent: React.FC<{
  error?: unknown;
  isPartnerNotFound: boolean;
  isAuthorized: boolean;
  globals: TGlobalVariables;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageProps: any;
  Component: TNextServerPage;
}> = ({error, isPartnerNotFound, isAuthorized, globals, pageProps, Component}) => {
  if (error) {
    const statusCode =
      error instanceof AxiosError || (error as Record<string, string>)['name'] === 'AxiosError'
        ? CustomStatusCodes.UpstreamError
        : HttpStatusCode.InternalServerError;
    return <Error statusCode={statusCode} />;
  }

  if (isPartnerNotFound) return <NotFoundPage />;

  if (!isAuthorized) return <Unauthorized />;

  return <Component {...pageProps} globals={globals} />;
};

/**
 * This component is the root component for every page in NextServer
 * The Component and pageProps props are the actual route being rendered.
 */
const AppShell: TAppShell = ({
  Component,
  bamPermissions,
  featureGates,
  user,
  partner,
  partnerInfo,
  roles,
  isTosSigned,
  pageProps,
  session,
  error,
  isAuthorized,
}) => {
  const globals = useMemo(
    () =>
      getGlobals({
        Component,
        bamPermissions,
        featureGates,
        user,
        partnerInfo,
        roles,
        isTosSigned,
      }),
    [Component, bamPermissions, featureGates, user, partnerInfo, roles, isTosSigned],
  );

  // We set all the globals to browser's window object using setWindowVariables. This should ensure window variables are available before
  // any component renders. It solves issue like this: https://bluecore.atlassian.net/browse/PROD-77568
  // Also, we do this here instead of in getInitialProps because getInitialProps do not run on client side for the initial page load.
  if (typeof window !== 'undefined') {
    setWindowVariables(globals);
  }

  useEffect(() => {
    if (user) setTelemetryUser(user);
  }, [user]);

  return (
    <SessionProvider session={session}>
      <ReactQueryProvider>
        <ThemeProvider>
          <PageHead title={Component.config?.title} globals={globals} />
          <AppContent
            error={error}
            isPartnerNotFound={partner != null && !partnerInfo && !Component.config?.allowUnauthenticated}
            isAuthorized={isAuthorized}
            globals={globals}
            pageProps={pageProps}
            Component={Component}
          />
        </ThemeProvider>
      </ReactQueryProvider>
    </SessionProvider>
  );
};

/**
 * This function is called on both the client and server side to send data down to the AppShell.
 */
AppShell.getInitialProps = async (appContext) => {
  const {Component, ctx, router} = appContext;
  const session = await getSession(ctx);

  const partner = Component.config?.partner || (router.query.partner as string);

  if (!Component.config?.allowUnauthenticated && !session) {
    redirectToLogin(ctx);
    return {};
  }
  // This must called before we make any API calls.
  if (isClient()) setupAxios(`${session?.rawJWT}`);

  // Check if the session has expired, if so redirect to the login page.
  const tokenExpires = Number(session?.tokenExpires);
  const isTokenExpired = tokenExpires && tokenExpires < Date.now();
  const isSessionValid = session != null && !isTokenExpired && session?.error !== 'RefreshAccessTokenError';

  if (!Component.config?.allowUnauthenticated && !isSessionValid) {
    redirectToLogin(ctx);
    return {};
  }

  if (ctx.pathname === '/login' && isSessionValid) {
    redirectToRoot(ctx);
    return {};
  }

  const {bamPermissions, featureGates, user, partnerInfo, roles, isTosSigned, initialProps, error} =
    await fetchInitialData({
      partner,
      isSessionValid: isSessionValid && ctx.pathname !== '/login' && ctx.pathname !== '/login/debug',
      appContext,
      jwt: session?.rawJWT ?? '',
    });

  if (
    !isTosSigned &&
    partnerInfo &&
    !bamPermissions.includes(ENGINEERING_TOOLS) &&
    Component.config?.requiresTermsOfService !== false
  ) {
    redirectToTermsOfService(ctx, partner);
    return {};
  }

  const isAuthorized =
    hasAllPermissions(new Set(bamPermissions), Component.config?.requiredPermissions) &&
    hasAllPermissions(new Set(featureGates), Component.config?.requiredFeatureGates);

  if (!isAuthorized && ctx.res) {
    ctx.res.statusCode = HttpStatusCode.Unauthorized;
  }

  if (partner && !partnerInfo && ctx.res && !Component.config?.allowUnauthenticated) {
    ctx.res.statusCode = HttpStatusCode.NotFound;
  }

  if (error && ctx.res) {
    if ((error as AxiosError)?.response?.status === HttpStatusCode.Unauthorized) {
      clearSession(ctx.res);
      redirectToLogin(ctx);
      return {};
    }

    ctx.res.statusCode = CustomStatusCodes.UpstreamError;
    ctx.res.setHeader('X-NextServer-Error', stringifyError(error));
  }

  return {
    ...initialProps,
    bamPermissions,
    featureGates,
    user,
    partner,
    partnerInfo,
    roles,
    isTosSigned,
    session,
    error,
    isAuthorized,
  };
};

export default appWithTranslation(AppShell, nextI18nConfig);
